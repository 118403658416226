import { Box } from "@mui/material";
import Header from "../../components/Header";
import moment from "moment"
import { Card, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { deleteData, getData, postData } from "../../methods";
import { ApiUrl } from "../../ApiRUL";
import html2pdf from 'html2pdf.js';

const QuoteDetails = () => {
  const location = useLocation();
  let eData = location.state.Edata || null;
  let EType = location.state.type || null;
  const [Category, setCategory] = useState([]);
  const [Enquiry, setEnquiry] = useState([]);
  const [QuoteData, setQuoteData] = useState([]);
  const [quoteItem, setquoteItem] = useState([]);
  const [ItemData, setItemData] = useState([]);
  const [Item, setItem] = useState({});
  const [FilteredItemData, setFilteredItemData] = useState([]);
  const [SubTotal, setSubTotal] = useState(0);
  const [IsQuote, setIsQuote] = useState(false);
  const [PayloadData, setPayloadData] = useState({
    adjustmentAmt: 0,
    category: "",
    qty: 1,
    note: "",
    item: "",
    region: ""
  });
  const [Rate, setRate] = useState(0)

  useEffect(() => {
    fetchData();

  }, [eData]);


  const fetchData = async () => {
    try {
      const [categoryResponse, itemsResponse, quoteResponse, quoteItemsResponse, enquiryResponse] = await Promise.all([
        getData(ApiUrl.GETCATEGORY),
        getData(ApiUrl.GETITEMS),
        getData(ApiUrl.GETQUOTE),
        getData(ApiUrl.GETQUOTEITEM),
        getData(ApiUrl.GETENQUIRY),
      ]);

      if (categoryResponse.status === 200) setCategory(categoryResponse.data);
      if (itemsResponse.status === 200) setItemData(itemsResponse.data);


      if (enquiryResponse.status === 200) {


        if (EType === "enquiry") {
          setEnquiry(eData);
        }
        if (EType === "quote") {
          let quoteenquir = enquiryResponse.data?.find((ele) =>
            ele._id === eData.enqId)
          setEnquiry(quoteenquir);
        }
      }



      if (quoteResponse.status === 200) {
        let filterData = await quoteItemsResponse?.data?.filter((ele) => ele.enqId === eData?._id)
        setQuoteData(filterData);
      }


      if (quoteItemsResponse.status === 200) {
        let filterquoteItem = await quoteItemsResponse?.data?.filter((item) => item.enqId === Enquiry?._id)
        setquoteItem(filterquoteItem);
        let total = await quoteItem?.reduce((accu, curr) => accu + Number(curr?.amount), 0);
        setSubTotal(total);
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === 'qty' || name === 'rate' || name === 'adjustmentAmt' ? parseFloat(value) || 0 : value;
    setPayloadData((prev) => ({ ...prev, [name]: newValue }));

    if (name === 'category') {
      const filteredItems = ItemData?.filter(
        (ele) => ele?.category?.toLowerCase() === value?.toLowerCase()
      );
      setFilteredItemData(filteredItems);
      setItem({});
      setPayloadData((prev) => ({ ...prev, item: '', rate: 0 }));
      // setSubTotal(0);
    }

    if (name === 'item') {
      const selectedItem = FilteredItemData?.find((ele) => ele._id === value);
      setItem(selectedItem);
      setRate(selectedItem?.offerPrice || 0);
    }


  };


  const handleAddService = async () => {
    try {
      const response = await postData(ApiUrl.ADDQUOTEITEM, {
        category: PayloadData?.category,
        item: Item.itemname,
        itemRate: PayloadData?.rate,
        adjustment: PayloadData?.adjustmentAmt,
        Qty: PayloadData?.qty,
        lastFDate: eData?.enquiryDate,
        quoteDate: new Date(),
        enqId: eData?._id,
        amount: Number(PayloadData?.qty) * Number(Rate) - Number(PayloadData?.adjustmentAmt),
        note: PayloadData?.note,
        region: PayloadData?.region
      });

      if (response.status === 200) {
        alert(response.data?.message);
        setPayloadData();
        window.location.reload("");
      }
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this quote item?")) {
      const response = await deleteData(ApiUrl.DELETEQUOTEITEM + id);
      if (response.status === 200) {
        alert(response.data.message);
        fetchData();
      }
    }
  };
  const handleSaveQuote = async () => {
    try {
      const response = await postData(ApiUrl.ADDQUOTE, {
        enqId: Enquiry?._id,
        subtotal: SubTotal,
        quoteId: `VHS#${QuoteData?.length + 1}`,
      });

      if (response.status === 200) {
        alert(response.data?.message);
        window.location.reload("");
      }
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handlePrint = () => {
    const element = document.getElementById('quote');
    const options = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: 'quotation.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: [8.5, 11], orientation: 'portrait' }
    };
    html2pdf().from(element).set(options).save();
  };



  return (
    <Box m="20px">
      <Header title="QUOTE" />
      <div className="row">
        <div className="enquir-tab">
          <a href="/quotelist" className="enquiry-btn p-1 me-3">
            Quotelist
          </a>

          <a href="/confirmed" className="enquiry-btn p-1 me-3">
            Confirmed
          </a>
        </div>
      </div>
      <div className="row mt-5 m-auto">
        {!IsQuote ? (
          <Card className="p-4">
            <h4>Billing Details</h4>
            <hr />
            <div className="row">
              <div className="col-md-4">
                <p className="m-auto">
                  <span className="main_h me-2">Enquiry Id :</span>
                  <span className="sub_h">{Enquiry?.enquiryId}</span>
                </p>
                <p className="m-auto">
                  <span className="main_h me-2">Email :</span>
                  <span className="sub_h">{Enquiry?.email}</span>
                </p>
              </div>
              <div className="col-md-4">
                <p className="m-auto">
                  <span className="main_h me-2">Mobile No :</span>
                  <span className="sub_h">{Enquiry?.contact1}</span>
                </p>
                <p className="m-auto">
                  <span className="main_h me-2">Address :</span>
                  <span className="sub_h">{Enquiry?.address}</span>
                </p>
              </div>
              <div className="col-md-4">
                <p className="m-auto">
                  <span className="main_h me-2">Customer Name :</span>
                  <span className="sub_h">{Enquiry?.customer}</span>
                </p>
              </div>
            </div>
            <div className="row">
              <button className="col-md-2 p-1 save mt-2 mb-2">
                Edit details
              </button>
            </div>
            <span className="main_h">Service Details</span>
            <hr />
            <div className="row">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <Form.Label>
                    Category <span className="red">*</span>
                  </Form.Label>
                  <Form.Select
                    className="mb-3"
                    onChange={handleChange}
                    value={PayloadData?.category}
                    name="category"
                  >
                    <option>Select Category</option>
                    {Category?.map((ele) => (
                      <option key={ele.category} value={ele.category}>
                        {ele.category}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Label>
                    Item <span className="red">*</span>
                  </Form.Label>
                  <Form.Select
                    value={PayloadData?.item}
                    type="text"
                    name="item"
                    onChange={handleChange}
                  >
                    <option>Select Item</option>
                    {FilteredItemData?.map((ele) => (
                      <option key={ele._id} value={ele._id}>
                        {ele.itemname}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="col-md-4 mb-3">
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control
                    type="text"
                    name="qty"
                    onChange={handleChange}
                    value={PayloadData?.qty}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <Form.Label>
                    Rate <span className="red">*</span>
                  </Form.Label>
                  <Form.Control
                    onChange={(e) => setRate(e.target.value)}
                    value={Rate}
                    type="text"
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <Form.Label>
                    Region
                  </Form.Label>

                  <Form.Select
                    onChange={handleChange}
                    value={PayloadData.region}
                    name="region"
                    type="text"
                  >
                    <option>Select Region</option>
                    <option value="Within City">Within City</option>
                    <option value="Outside City">Outside City</option>
                  </Form.Select>
                </div>

                <div className="col-md-6 mb-3">
                  <Form.Label>Adjustment</Form.Label>
                  <Form.Control
                    type="text"
                    value={PayloadData?.adjustmentAmt}
                    name="adjustmentAmt"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Label>
                    Note <span className="red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="note"
                    onChange={handleChange}
                    value={PayloadData?.note}
                  />
                </div>
                <div className="row">
                  <div className="col-md-1 me-auto"></div>
                  <div className="col-md-3 mb-3">
                    <span className="main_h"> Amount :</span>{" "}
                    <span className="sub_h">{Number(PayloadData.qty) * Number(Rate) - Number(PayloadData.adjustmentAmt)}</span>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-5 m-auto">
                  <div className="row m-auto">
                    <button
                      className="col-md-4 m-auto p-1 save me-2"
                      onClick={handleAddService}
                    >
                      Add Service
                    </button>
                    <button className="col-md-4 m-auto p-1 save">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <table>
                <thead className="text-center th_t">
                  <tr>
                    <th className="p-2">#</th>
                    <th className="p-2">Category</th>
                    <th className="p-2">Region</th>
                    <th className="p-2">Item</th>
                    <th className="p-2">Qty</th>
                    <th className="p-2">Amount</th>
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>

                  {
                    quoteItem?.length > 0 && (quoteItem?.map((ele, index) => {
                      return (
                        <tr className="text-center">
                          <td className="p-2">{index + 1}</td>
                          <td className="p-2">{ele?.category}</td>
                          <td className="p-2">{ele?.region}</td>
                          <td className="p-2">{ele?.item}</td>
                          <td className="p-2">{ele?.Qty}</td>
                          <td className="p-2">{ele?.amount}</td>
                          <td className="p-2 cursor" onClick={() => handleDelete(ele._id)}>Delete</td>
                        </tr>
                      )
                    }))
                  }
                  <tr className="text-center">
                    <td className="p-2" colSpan={5}></td>
                    <td className="p-2">{SubTotal}</td>
                    <td className="p-2"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="row mt-3">
              <div className="col-md-5 m-auto">
                <div className="row m-auto">
                  <button className="col-md-4 m-auto p-1 save me-2" onClick={handleSaveQuote}>
                    Save Quote
                  </button>
                  <button
                    className="col-md-4 m-auto p-1 save"
                    onClick={() => setIsQuote(true)}
                  >
                    Print Quote
                  </button>
                </div>
              </div>
            </div>
          </Card>
        ) : (
          <>
            <div className="row float-end">
              <button className="save col-md-2 p-2" onClick={handlePrint}>Download</button>
            </div>
            <div className="row shadow bg-white mb-5 mt-3 p-2 m-auto" id="quote">
              <div className="row p-4">
                <div className="col-md-3 me-auto">
                  <div className="row text-center">
                    <div className="col-md-6">
                      <img
                        src="../assets/logo192.png"
                        width={80} height={80}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-3  me-0">
                  <div className="row text-end">
                    <p className="sub_h m-auto">Quotation : {QuoteData?.quoteId}</p>
                    <p className="sub_h m-auto">Date : {moment(new Date()).format("YY-MM-DD")}</p>
                  </div>
                </div>
              </div>

              <div className="row p-4">
                <div className="col-md-6  me-0">
                  <div className="row">
                    <p className="main_h">From</p>
                  </div>

                  <div className="row">
                    <span className="sub_h m-auto">vijayahome service</span>{" "}
                  </div>
                  <div className="row">
                    <span className=" m-auto">9902995376</span>
                  </div>
                  <div className="row">
                    <p className="m-auto">vijayahomeservice@gmail.com</p>
                  </div>
                  <div className="row ">
                    <p className="m-auto">Website</p>
                    <a
                      className="m-auto text-decoration-none text-dark"
                      href="https://www.vijayhomeservices.com/Bangalore"
                    >
                      https://www.vijayhomeservices.com
                    </a>
                  </div>
                </div>
                <div className="col-md-6  me-0">
                  <div className="row text-end">
                    <p className="main_h">Bill To</p>
                    <p className="sub_h m-auto">{Enquiry?.customer}</p>
                    <p className=" m-auto">{Enquiry?.contact1}</p>
                    <p className=" m-auto">{Enquiry?.email}</p>{" "}
                    <p className="m-auto">{Enquiry?.address}</p>{" "}
                  </div>
                </div>
              </div>
              <div className="row m-auto">
                <table>
                  <thead className="text-center">
                    <th className="p-2">#</th>
                    <th className="p-2">Category</th>
                    <th className="p-2">Scop of job</th>
                    <th className="p-2">Qty</th>
                    <th className="p-2">Amount</th>
                  </thead>
                  <tbody>

                    {
                      quoteItem?.map((ele, index) => {
                        return (
                          <tr className="text-center">
                            <td className="p-2">{index + 1}</td>
                            <td className="p-2">{ele?.category}</td>
                            <td className="p-2">{ele?.item}</td>
                            <td className="p-2">{ele?.Qty}</td>
                            <td className="p-2">{ele?.amount}</td>
                          </tr>
                        )
                      })
                    }


                    <tr className="text-center">
                      <td className="p-2" colSpan={3}></td>
                      <td>Total Amount</td>
                      <td className="p-2">{SubTotal}</td>
                    </tr>

                  </tbody>
                </table>
                <div className="row mt-4">
                  <div className="col-md-4 me-auto ">
                    <p className="main_h"> BANK DETAILS</p>
                    <p className="m-auto">
                      {" "}
                      <span className="main_h">Account Name :</span>{" "}
                      <span className="sub_hh"> VIJAY HOME SERVICES</span>
                    </p>
                    <p className="m-auto">
                      {" "}
                      <span className="main_h"> Account Number :</span>{" "}
                      <span className="sub_hh">9446228221</span>
                    </p>
                    <p className="m-auto">
                      {" "}
                      <span className="main_h"> IFSC :</span>{" "}
                      <span className="sub_hh">KKBK0008066</span>
                    </p>
                    <p className="m-auto">
                      {" "}
                      <span className="main_h"> BANK NAME :</span>{" "}
                      <span className="sub_hh">KOTAK BANK</span>
                    </p>
                    <p className="m-auto">
                      {" "}
                      <span className="main_h">Branch Name :</span>{" "}
                      <span className="sub_hh">M.G.ROAD</span>
                    </p>
                    <p className="m-auto">
                      {" "}
                      <span className="main_h">
                        Gpay / Phonepe Details Mobile No. :
                      </span>{" "}
                      <span className="sub_hh">8073077921</span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p className="row ">
                      {" "}
                      <span className="col-md-10 me-auto main_h">
                        GST(5%) :
                      </span>{" "}
                      <span className="col-md-2 sub_hh"> 0</span>
                    </p>
                    <p className="row ">
                      {" "}
                      <span className="col-md-10 me-auto main_h">
                        Total :
                      </span>{" "}
                      <span className="col-md-2 sub_hh"> {SubTotal}</span>
                    </p>
                    <p className="m-auto">
                      {" "}
                      <span className="main_h">In Words :</span>{" "}
                      <span className="sub_hh">Two Thousand only</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </>
        )}
      </div>
    </Box>
  );
};

export default QuoteDetails;

