import { useTheme } from "@mui/material";
// import { ResponsiveChoropleth } from "@nivo/geo";
// import { geoFeatures } from "../data/mockGeoFeatures";
import { tokens } from "../theme";
// import { mockGeographyData as data } from "../data/mockData";

const GeographyChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return <div></div>;
};

export default GeographyChart;
