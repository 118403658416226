
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import CustomColumnMenu from "../customgrid";
import { ApiUrl } from "../../ApiRUL";
import { getData, deleteData } from "../../methods";
import { useEffect, useState } from "react";
import { MdDeleteForever } from "react-icons/md";
const Confirmed = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [QuoteData, setQuoteData] = useState([])
  const [QuoteItem, setQuoteItem] = useState([])


  const columns = [
    { field: "index", headerName: "#" },
    {
      field: "category",
      headerName: "Category",
      width: 200,
    },
    {
      field: "quoteId",
      headerName: "QID",
    },
    {
      field: "QDate",
      headerName: "Q Dt-Tm",
    },

    {
      field: "customer",
      headerName: "Name",
    },

    {
      field: "contact",
      headerName: "Contact",
    },

    {
      field: "address",
      headerName: "Address",
      width: 250,
    },

    {
      field: "city",
      headerName: "City",
    },

    {
      field: "service",
      headerName: "Service",
    },

    {
      field: "amount",
      headerName: "QAmt",
    },

    {
      field: "excutive",
      headerName: "Executive",
    },

    {
      field: "lastFDate",
      headerName: "Last F/W Dt",
    },


    {
      field: "desc",
      headerName: "Desc",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: ({ row }) => (
        <div color={colors.grey[100]} sx={{ ml: "5px" }}>

          <MdDeleteForever
            className="cursor delete fs-6"
            onClick={() => handleDelete(row.id)}
          />
        </div>
      ),
    }
  ];

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this service?")) {
      const response = await deleteData(ApiUrl.DELETEQUOTE + id);
      if (response.status === 200) {
        alert(response.data.message);
        window.location.reload();

      }
    }
  };
  const navigate = useNavigate();
  const handleQuoteDetails = (data) => {

    navigate("/quotedetails", { state: { Edata: data.row, type: "quote" } });
  };
  useEffect(() => { fetchData() }, [])
  const fetchData = async () => {
    try {

      const [quoteResponse, enquiryResponse, fallowupResponse] = await Promise.all([
        getData(ApiUrl.GETQUOTE),
        getData(ApiUrl.GETENQUIRY),
        getData(ApiUrl.GETFALLOWUP),
      ]);



      if (quoteResponse.status === 200) {

        const Fallowup = fallowupResponse.data?.filter((ele) => ele.response === "Confirmed")
        const quotes = quoteResponse.data?.filter((ele) =>
          Fallowup?.find((fallo) => fallo.enquiryId === ele.enqId)
        )


        const enquiries = enquiryResponse.data?.filter((ele) =>
          Fallowup?.find((fallo) => fallo.enquiryId === ele._id)
        )


        const rowsWithId = quotes?.map((quote, index) => {
          const enquiry = enquiries?.find(enquiry => quote.enqId === enquiry._id);


          return {
            ...quote,
            id: quote?._id,
            index: index + 1,
            customer: enquiry?.customer,
            category: enquiry?.category,
            contact: enquiry?.contact1,
            QDate: quote?.updatedAt,
            excutive: enquiry?.excutive,
            address: enquiry?.address,
            city: enquiry?.city,
            service: enquiry?.service,
            excutive: enquiry?.excutive,
            enqId: enquiry?._id
          };

        }
        );

        setQuoteData(rowsWithId);

      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Box m="20px">
      <Header title="QUOTE" />
      <div className="row">
        <div className="enquir-tab">
          <a href="/quotelist" className="enquiry-btn p-1 me-3">
            Quotelist
          </a>
        </div>
      </div>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          onRowClick={handleQuoteDetails}
          rows={QuoteData}
          columns={columns} components={{
            ColumnMenu: CustomColumnMenu,
          }}
        />
      </Box>
    </Box>
  );
};

export default Confirmed;

